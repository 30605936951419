.text {
  font-size: 12px !important;
}

.switch {
  font-size: 14px !important;
}

.text a:link, .text a:visited, .text a:active {
  /* color: red; */
  color: #429EBD;
}

.text a:hover {
  color: #3691B0;
}

.switch a:link, .switch a:visited, .switch a:active {
  /* color: red; */
  color: #429EBD;
}

.switch a:hover {
  color: #3691B0;
}